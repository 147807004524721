<template>
    <Popover v-if="pronoun.getMorpheme(morpheme, counter)" resize>
        <Morpheme
            :pronoun="pronoun"
            :morpheme="morpheme"
            :counter="counter"
            :prepend="prepend"
            :append="append"
            :highlights-morphemes="highlightsMorphemes"
        />
        <Pronunciation v-if="pronunciation" :pronunciation="pronunciation" text />
        <template v-if="examples.length > 0" #content>
            <ul class="my-0 ps-3">
                <li v-for="example in examples" class="my-1">
                    <Example
                        :example="example"
                        :pronoun="pronoun"
                        :counter="counter"
                    />
                </li>
            </ul>
        </template>
    </Popover>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import type { PropType } from 'vue';

import { Pronoun } from '../src/classes.ts';
import type { Example } from '../src/classes.ts';
import { examples } from '../src/data.ts';

export default defineComponent({
    props: {
        pronoun: { required: true, type: Pronoun },
        morpheme: { required: true, type: String },
        counter: { default: 0, type: Number },

        prepend: { default: '', type: String },
        prependPr: { default: '', type: String },
        append: { default: '', type: String },
        appendPr: { default: '', type: String },
        highlightsMorphemes: {
            default(props: { morpheme: string }) {
                if (props.morpheme.startsWith('\'')) {
                    return new Set([props.morpheme.substring(1)]);
                }
                return new Set([props.morpheme]);
            },
            type: Set as PropType<Set<string>>,
        },
    },
    computed: {
        examples(): Example[] {
            return examples.filter((example) => {
                return example.requiredMorphemesPresent(this.pronoun, this.counter) &&
                    [...this.highlightsMorphemes.values()].some((morpheme) => example.hasMorpheme(morpheme));
            });
        },
        pronunciation(): string | null {
            const pronunciation = this.pronoun.getPronunciation(this.morpheme, this.counter);
            if (!this.pronoun.pronounceable || !pronunciation || pronunciation.startsWith('=')) {
                return null;
            }
            return `/${this.prependPr}${this.pronoun.getPronunciation(this.morpheme, this.counter)}${this.appendPr}/`;
        },
    },
});
</script>
